@import "https://fonts.googleapis.com/css2?family=Bangers&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.super-hero {
  --super-hero-btn-bg: $ teal;
  --super-hero-btn-shadow: 4px 4px 0px 0px #000;
  --super-hero-btn-border-radius: 60px;
  color: #000;
  flex-direction: column;
  align-items: center;
  margin: 0;
  font-family: Open Sans, sans-serif;
  line-height: 1.4;
  display: flex;
}

.super-hero-container {
  width: 100%;
  max-width: 1276px;
  margin-left: auto;
  margin-right: auto;
}

.super-hero-form {
  background: #fff;
}

@media screen and (min-width: 1024px) {
  .super-hero-form {
    border: 2px solid #000;
    padding: 24px;
  }
}

.super-hero-form * {
  box-sizing: border-box;
}

.super-hero-form label, .super-hero-form input, .super-hero-form select {
  margin-top: 10px;
  display: block;
}

.super-hero-form input[type="submit"] {
  margin-top: 30px;
}

.super-hero-form-step {
  --super-hero-img-text-display: none;
  width: 100%;
  min-height: 750px;
  text-align: center;
  background: #fff;
  border: 8px solid #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-step {
    height: 800px;
    padding: 68px;
  }
}

.super-hero-form-step * {
  position: relative;
}

.super-hero-form-step[data-step="1"] {
  justify-content: center;
  align-items: flex-end;
  padding: 40px 15px;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-step[data-step="1"] {
    justify-content: flex-start;
    padding: 200px 160px;
  }
}

.super-hero-form-step[data-step="2"] {
  background-color: #00b3a4;
}

.super-hero-form-step[data-step="3"] {
  background-color: #f6c106;
}

.super-hero-form-step[data-step="4"] {
  background-color: #b03535;
  padding: 24px 15px;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-step[data-step="4"] {
    padding: 40px 60px;
  }
}

.super-hero-form-bg, .super-hero-form-mobile-bg, .super-hero-form-superHerosImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.super-hero-form-bg {
  display: none;
}

@media screen and (min-width: 720px) {
  .super-hero-form-bg {
    display: block;
  }
}

.super-hero-form-mobile-bg {
  display: block;
}

@media screen and (min-width: 720px) {
  .super-hero-form-mobile-bg {
    display: none;
  }
}

.super-hero-form-superHerosImg {
  height: 460px;
  object-position: 22%;
  z-index: 3;
  top: 50px;
}

@media screen and (min-width: 720px) {
  .super-hero-form-superHerosImg {
    height: 100%;
    top: auto;
  }
}

@media screen and (min-width: 1024px) {
  .super-hero-form-superHerosImg {
    object-fit: cover;
    object-position: center;
  }
}

.super-hero-form-raster-top {
  z-index: 0;
  max-width: 160px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-raster-top {
    max-width: 225px;
  }
}

.super-hero-form-raster-bottom {
  z-index: 0;
  max-width: 160px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-raster-bottom {
    max-width: 225px;
  }
}

.super-hero-form-heading {
  z-index: 4;
  max-width: 500px;
  margin-top: 40px;
}

@media screen and (min-width: 720px) {
  .super-hero-form-heading {
    margin-top: 0;
  }
}

.super-hero-form-heading img {
  width: 100%;
  height: auto;
}

.super-hero-form-step-label {
  color: #fff;
  text-shadow: 3px 3px #000;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  font-family: Open Sans, sans-serif;
  font-size: 28px;
  font-weight: 800;
  display: flex;
}

.super-hero-form-step-label-line {
  width: 36px;
  border-top: 1px solid #fff;
  margin: 0 20px;
  display: inline-block;
  box-shadow: -2px 2px #000;
}

.super-hero-form-label {
  color: #fff;
  text-shadow: 6px 6px #000;
  -webkit-text-stroke-width: 2;
  -webkit-text-stroke-color: #000;
  margin-top: 5px;
  margin-bottom: 50px;
  font-family: Open Sans, sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 1;
}

@media screen and (min-width: 720px) {
  .super-hero-form-label {
    text-shadow: -6px 6px #000;
    font-size: 64px;
  }
}

.super-hero-form-label-pre {
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 800;
}

.super-hero-form-step[data-step="1"] .super-hero-form-label {
  z-index: 2;
  text-shadow: -6px 6px #000;
  margin-bottom: 0;
  font-size: 72px;
}

@media screen and (min-width: 720px) {
  .super-hero-form-step[data-step="1"] .super-hero-form-label {
    text-shadow: -12px 12px #000;
    font-size: 120px;
  }
}

.super-hero-form-step[data-step="4"] .super-hero-form-label {
  color: #000;
  text-shadow: none;
  margin-bottom: 30px;
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 800;
}

@media screen and (min-width: 720px) {
  .super-hero-form-step[data-step="4"] .super-hero-form-label {
    margin-bottom: 50px;
    font-size: 32px;
  }
}

.super-hero-form-btns {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}

.super-hero-form-step[data-step="4"] .super-hero-form-btns {
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  bottom: 0;
}

@media screen and (min-width: 720px) {
  .super-hero-form-step[data-step="4"] .super-hero-form-btns {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1440px) {
  .super-hero-form-step[data-step="4"] .super-hero-form-btns {
    margin-top: 100px;
  }
}

.super-hero-form-btn {
  color: #000;
  cursor: pointer;
  box-shadow: var(--super-hero-btn-shadow);
  border-radius: var(--super-hero-btn-border-radius);
  z-index: 4;
  background-color: #18dcd1;
  border: 2px solid #000;
  padding: 16px 80px;
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.2;
  display: inline-block;
}

@media screen and (min-width: 720px) {
  .super-hero-form-btn {
    padding: 24px 80px;
    font-size: 32px;
  }
}

.super-hero-form-btn:hover {
  background-color: #ffcf35;
}

.super-hero-form-step[data-step="1"] .super-hero-form-btn {
  width: 100%;
  margin-top: 40px;
  font-size: 24px;
}

@media screen and (min-width: 720px) {
  .super-hero-form-step[data-step="1"] .super-hero-form-btn {
    width: auto;
    margin-top: 0;
    font-size: 32px;
  }
}

.super-hero-form-step[data-step="4"] .super-hero-form-btn {
  text-align: center;
  width: 100%;
  border-radius: var(--super-hero-btn-border-radius);
  padding: 16px 40px;
  font-size: 18px;
  font-weight: 800;
}

@media screen and (min-width: 720px) {
  .super-hero-form-step[data-step="4"] .super-hero-form-btn {
    padding: 20px 40px;
    font-size: 24px;
  }
}

.super-hero-form-superHeroPowerBg {
  width: 100%;
  object-fit: fill;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.super-hero-form-superHeroPowerImg {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-superHeroPowerImg {
    display: block;
  }
}

.super-hero-form-superHeroTypeBg {
  width: 100%;
  object-fit: fill;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.super-hero-form-superHeroTypeImg {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-superHeroTypeImg {
    display: block;
  }
}

.super-hero-form-superHeroNameBg, .super-hero-form-superHeroNameMobileBg {
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.super-hero-form-superHeroNameBg {
  display: none;
}

@media screen and (min-width: 720px) {
  .super-hero-form-superHeroNameBg {
    display: block;
  }
}

.super-hero-form-superHeroNameMobileBg {
  display: block;
}

@media screen and (min-width: 720px) {
  .super-hero-form-superHeroNameMobileBg {
    display: none;
  }
}

.super-hero-form-cards {
  width: 100%;
  max-width: 320px;
  flex-flow: wrap;
  justify-content: center;
  gap: 15px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .super-hero-form-cards {
    max-width: 680px;
    gap: 40px;
    margin-bottom: 100px;
  }
}

.super-hero-form-result {
  text-align: left;
  z-index: 4;
  color: #fff;
  height: 100%;
  flex-direction: column-reverse;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 720px) {
  .super-hero-form-result {
    max-width: 600px;
  }
}

@media screen and (min-width: 1024px) {
  .super-hero-form-result {
    max-width: 100%;
    flex-direction: row-reverse;
    gap: 80px;
  }
}

.super-hero-form-result-col {
  height: 100%;
  flex: 1;
}

.super-hero-form-result-col:last-child {
  flex: 0 50%;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-col:last-child {
    flex: 0 540px;
  }
}

.super-hero-form-result-heading {
  margin-bottom: 4rem;
  font-size: 2rem;
  font-weight: bold;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-result-content {
    margin-bottom: 100px;
  }
}

.super-hero-form-result-intro {
  text-shadow: 2px 2px #000;
  margin-bottom: 24px;
  font-size: 1.25rem;
  font-weight: 800;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-intro {
    margin-top: 80px;
    margin-bottom: 40px;
    font-size: 1.5rem;
  }
}

.super-hero-form-result-intro a {
  color: #fff;
  text-underline-offset: 4px;
}

.super-hero-form-result-card {
  background: #fff;
  border: 6px solid #000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 4px 4px #000;
}

.super-hero-form-result-card-head {
  color: #fff;
  text-align: center;
  background-color: #a7a7a7;
  background-position: bottom;
  background-repeat: space no-repeat;
  background-size: 80px;
  border-bottom: 6px solid #000;
  padding: 20px 24px 50px;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-card-head {
    padding: 60px 40px;
  }
}

.super-hero-form-result-card-head.super-purple {
  background-color: #8a049f;
}

.super-hero-form-result-card-head.super-red {
  background-color: #c20d2c;
}

.super-hero-form-result-card-head.super-orange {
  background-color: #fb8213;
}

.super-hero-form-result-card-head.super-green {
  background-color: #07a029;
}

.super-hero-form-result-card-head.super-teal {
  background-color: #0dc3b9;
}

.super-hero-form-result-card-head-bg {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.super-hero-form-result-card-head-bg svg {
  width: 100%;
  height: auto;
  color: rgba(255, 255, 255, .2);
  position: relative;
}

.super-hero-form-result-card-icon {
  width: 60px;
  height: 60px;
  background: #656565;
  border: 4px solid #000;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  margin-left: -30px;
  display: flex;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 4px 0 #000;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-card-icon {
    width: 75px;
    height: 75px;
    margin-top: -37.5px;
    margin-left: -37.5px;
  }
}

.super-hero-form-result-card-icon.super-purple {
  background-color: #8a049f;
}

.super-hero-form-result-card-icon.super-red {
  background-color: #c20d2c;
}

.super-hero-form-result-card-icon.super-orange {
  background-color: #fb8213;
}

.super-hero-form-result-card-icon.super-green {
  background-color: #07a029;
}

.super-hero-form-result-card-icon.super-teal {
  background-color: #0dc3b9;
}

.super-hero-form-result-card-icon svg {
  width: 35px;
  height: 35px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-card-icon svg {
    width: 50px;
    height: 50px;
  }
}

.super-hero-form-result-card-body {
  color: #1e1e1e;
  padding: 50px 24px 24px;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-card-body {
    padding: 80px 40px 40px;
  }
}

.super-hero-form-result-card-body-attr {
  margin: 0 0 20px;
  font-size: 1rem;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-card-body-attr {
    font-size: 1.25rem;
  }
}

.super-hero-form-result-card-body-attr label {
  margin: 0;
  font-weight: bold;
  display: inline-block;
}

.super-hero-form-result-card-body-origin {
  margin: 20px 0;
  font-size: 1rem;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-card-body-origin {
    margin: 24px 0;
    font-size: 1.25rem;
  }
}

.super-hero-form-result-card-body-origin label {
  margin: 0;
  font-weight: bold;
  display: inline-block;
}

.super-hero-form-result-card-body-logo {
  width: 140px;
  height: auto;
  margin: 0;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-card-body-logo {
    width: 200px;
  }
}

.super-hero-form-result-card-heading {
  color: #fff;
  text-shadow: 4px 4px #000;
  stroke-width: 1px;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  text-transform: capitalize;
  font-size: 28px;
  font-weight: 800;
}

@media screen and (min-width: 1200px) {
  .super-hero-form-result-card-heading {
    stroke-width: 2px;
    -webkit-text-stroke-width: 2;
    font-size: 38px;
  }
}

.super-hero-form-result-card-subheading {
  color: #fff;
  text-transform: uppercase;
  text-shadow: 2px 2px #000;
  margin-bottom: .75rem;
  font-size: .75rem;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-result-card-subheading {
    text-shadow: 4px 4px #000;
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
  }
}

.super-hero-form-result-love-img {
  display: none;
  position: absolute;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-result-love-img {
    width: 780px;
    display: block;
    bottom: 24px;
    right: -24px;
  }
}

.super-hero-form-result-love-img.mobile {
  width: 120%;
  margin: 40px -10% 0;
  display: block;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .super-hero-form-result-love-img.mobile {
    display: none;
  }
}

.super-hero-form-superHeroType .super-hero-form-cards {
  max-width: 480px;
}

.super-hero-form-card {
  width: 150px;
  height: 150px;
  background-color: #fff;
  border: 3px solid #000;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 800;
  box-shadow: 4px 4px #000;
}

@media screen and (min-width: 768px) {
  .super-hero-form-card {
    width: 200px;
    height: 200px;
    font-size: 22px;
  }
}

.super-hero-form-card:hover {
  cursor: pointer;
  box-shadow: 6px 6px #000;
}

.super-hero-form-card-outer {
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.super-hero-form-card-inner {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  row-gap: 10px;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .super-hero-form-card-inner {
    padding: 20px;
  }
}

.super-hero-form-card-svg {
  width: 66px;
  height: 66px;
  color: #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .super-hero-form-card-svg {
    width: 90px;
    height: 90px;
  }
}

.super-hero-form-card-svg.super-purple {
  background-color: #8a049f;
}

.super-hero-form-card-svg.super-red {
  background-color: #c20d2c;
}

.super-hero-form-card-svg.super-orange {
  background-color: #fb8213;
}

.super-hero-form-card-svg.super-green {
  background-color: #07a029;
}

.super-hero-form-card-svg.super-teal {
  background-color: #0dc3b9;
}

.super-hero-form-card-svg svg {
  width: auto;
  height: 30px;
}

@media screen and (min-width: 768px) {
  .super-hero-form-card-svg svg {
    height: 50px;
  }
}

.super-hero-form-card-svg svg.super-hero-form-card-svg-hover {
  width: 140px;
  height: 140px;
  display: none;
  position: absolute;
}

.super-hero-form-card-svg.super-purple .super-hero-form-card-svg-hover {
  color: #8a049f;
}

.super-hero-form-card-svg.super-red .super-hero-form-card-svg-hover {
  color: #c20d2c;
}

.super-hero-form-card-svg.super-orange .super-hero-form-card-svg-hover {
  color: #fb8213;
}

.super-hero-form-card-svg.super-green .super-hero-form-card-svg-hover {
  color: #07a029;
}

.super-hero-form-card-svg.super-teal .super-hero-form-card-svg-hover {
  color: #0dc3b9;
}

.super-hero-form-card:hover .super-hero-form-card-svg {
  background-color: rgba(0, 0, 0, 0);
}

.super-hero-form-card:hover .super-hero-form-card-svg-hover {
  display: block;
}

.super-hero-form-card-label {
  text-align: center;
}

.super-hero-form-name {
  color: #183d7b;
  text-shadow: -3px 3px #ccc;
  z-index: 1;
  font-family: Bangers, cursive;
  font-size: 48px;
  font-weight: 400;
  line-height: 1;
}

@media screen and (min-width: 720px) {
  .super-hero-form-name {
    text-shadow: -4px 4px #ccc;
    font-size: 80px;
  }
}

.super-hero-form-rename {
  cursor: pointer;
  z-index: 1;
  margin-top: 30px;
  font-size: 14px;
}

@media screen and (min-width: 720px) {
  .super-hero-form-rename {
    font-size: 16px;
  }
}

.super-hero-form-rename-icon {
  margin-right: 5px;
}

.super-hero-form-text {
  z-index: 1;
  max-width: 280px;
  margin-bottom: -100px;
  font-size: 14px;
}

.super-hero-form-text a {
  color: #183d7b;
}

@media screen and (min-width: 720px) {
  .super-hero-form-text {
    max-width: 640px;
    margin-bottom: -155px;
    font-size: 20px;
  }
}

.super-hero-form-img-text {
  z-index: 0;
  max-width: 280px;
  font-size: 14px;
  position: relative;
}

@media screen and (min-width: 720px) {
  .super-hero-form-img-text {
    max-width: 640px;
    margin-bottom: 0;
    font-size: 20px;
  }
}

.super-hero-form-img-text-overlay {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
}

.super-hero-form-logo {
  width: 150px;
  margin: 10px auto 0;
  display: block;
}

@media screen and (min-width: 720px) {
  .super-hero-form-logo {
    width: 200px;
    margin: 30px auto 0;
  }
}

.super-hero-form-hr {
  z-index: 1;
  height: 1px;
  width: 200px;
  background: #ccc;
  margin: 20px 0;
}

@media screen and (min-width: 720px) {
  .super-hero-form-hr {
    width: 420px;
    margin: 40px 0;
  }
}

.super-hero-modal {
  --modal-width: 1000px;
  --modal-margin: 30px;
  --modal-padding: 60px;
  --modal-btn-close: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --modal-btn-close-color: #000;
  --modal-btn-close-opacity: .5;
  z-index: 10050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.super-hero-modal-backdrop {
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.super-hero-modal-backdrop.super-hero-modal-show {
  opacity: .5;
}

.super-hero-modal.super-hero-modal-fade, .super-hero-modal-backdrop.super-hero-modal-fade {
  transition: opacity .15s linear;
}

.super-hero-modal.super-hero-modal-fade:not(.super-hero-modal-show), .super-hero-modal-backdrop.super-hero-modal-fade:not(.super-hero-modal-show) {
  opacity: 0;
}

.super-hero-modal-dialog {
  width: auto;
  margin: var(--modal-margin);
  pointer-events: none;
  position: relative;
}

@media screen and (min-width: 1020px) {
  .super-hero-modal-dialog {
    max-width: var(--modal-width);
    margin-left: auto;
    margin-right: auto;
  }
}

.super-hero-modal-dialog-centered {
  min-height: calc(100% - var(--modal-margin) * 2);
  align-items: center;
  display: flex;
}

.super-hero-modal.super-hero-modal-fade .super-hero-modal-dialog {
  transition: -webkit-transform .3s ease-out, transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

.super-hero-modal.super-hero-modal-show .super-hero-modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.super-hero-modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 50px 24px 40px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  .super-hero-modal-content {
    flex-direction: row;
    padding: 40px;
  }
}

.super-hero-modal-content img {
  height: auto;
  width: 350px;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 768px) {
  .super-hero-modal-content img {
    height: auto;
    max-width: 450px;
  }
}

.super-hero-modal-header, .super-hero-modal-body, .super-hero-modal-footer {
  padding: var(--modal-padding);
  display: flex;
}

.super-hero-modal-body {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0 0;
  position: relative;
}

@media screen and (min-width: 768px) {
  .super-hero-modal-body {
    padding: 0 var(--modal-padding);
  }
}

.super-hero-modal-actions {
  width: 100%;
  color: #183d7b;
  column-gap: 40px;
  margin-top: 24px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

@media screen and (min-width: 768px) {
  .super-hero-modal-actions {
    font-size: 24px;
  }
}

.super-hero-modal-actions a {
  color: #183d7b;
  cursor: pointer;
  text-decoration: underline;
}

.super-hero-modal-btn-close {
  cursor: pointer;
  box-sizing: content-box;
  width: 2em;
  height: 2em;
  color: var(--modal-btn-close-color);
  background: transparent var(--modal-btn-close) center / 1em auto no-repeat;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 100px;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}

@media screen and (min-width: 768px) {
  .super-hero-modal-btn-close {
    top: 40px;
    right: 40px;
  }
}

.super-hero-modal-btn-close:hover {
  border: 1px solid var(--modal-btn-close-color);
}

.super-hero-modal-header .super-hero-modal-btn-close {
  padding: calc(var(--modal-padding) * .5) calc(var(--modal-padding) * .5);
  margin: calc(-.5 * var(--modal-padding)) calc(-.5 * var(--modal-padding)) calc(-.5 * var(--modal-padding)) auto;
}

.super-hero-modal-instructions {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .super-hero-modal-instructions {
    font-size: 20px;
  }
}

.super-hero-modal-footer {
  flex-wrap: wrap;
  gap: 15px;
  font-size: 18px;
  font-weight: 600;
}

.super-hero-modal-footer svg {
  color: #183d7b;
}

.super-hero-modal-footer a {
  color: #183d7b;
  text-decoration: none;
}

.super-hero-modal-footer a:hover {
  cursor: pointer;
  color: #183d7b;
  text-decoration: underline;
}

.super-hero-modal-footer-copy {
  display: none;
}

@media screen and (min-width: 768px) {
  .super-hero-modal-footer-copy {
    display: block;
  }

  .super-hero-modal-footer-share {
    display: none;
  }
}

/*# sourceMappingURL=index.css.map */
