.super-hero-modal {
    --modal-width: 1000px;
    --modal-margin: 30px;
    --modal-padding: 60px;
    --modal-btn-close: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    --modal-btn-close-color: #000;
    --modal-btn-close-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10050;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    &-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        width: 100vw;
        height: 100vh;
        display: none;
        background-color: #000;

        &.super-hero-modal-show {
            opacity: .5;
        }
    }

    &.super-hero-modal-fade, &-backdrop.super-hero-modal-fade {
        transition: opacity .15s linear;
    }

    &.super-hero-modal-fade:not(.super-hero-modal-show), &-backdrop.super-hero-modal-fade:not(.super-hero-modal-show) {
        opacity: 0;
    }


    &-dialog {
        position: relative;
        width: auto;
        margin: var(--modal-margin);
        pointer-events: none;

        @media screen and (min-width: 1020px) {
            max-width: var(--modal-width);
            margin-left: auto;
            margin-right: auto;
        }

        &-centered {
            display: flex;
            align-items: center;
            min-height: calc(100% - var(--modal-margin) * 2);
        }
    }

    &.super-hero-modal-fade &-dialog {
        transition: transform .3s ease-out;
        transform: translate(0,-50px);
    }

    &.super-hero-modal-show &-dialog {
        transform: none;
    }

    &-content {
        padding: 50px 24px 40px;
        background-color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        pointer-events: auto;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            padding: 40px;
        }

        img {
            height: auto;
            width: 350px;
            max-width: 100%;
            object-fit: cover;
            object-position: center;

            @media screen and (min-width: 768px) {
                height: auto;
                max-width: 450px;
            }
        }
    }

    &-header, &-body, &-footer {
        display: flex;
        padding: var(--modal-padding);
    }

    &-body {
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px 0 0;

        @media screen and (min-width: 768px) {
            padding: 0 var(--modal-padding);
        }
    }

    &-actions {
        width: 100%;
        color: #183D7B;
        font-size: 20px;
        font-weight: 600;
        margin-top: 24px;
        display: flex;
        column-gap: 40px;

        @media screen and (min-width: 768px) {
            font-size: 24px;
        }

        a {
            color: #183D7B;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &-btn-close {
        cursor: pointer;
        box-sizing: content-box;
        width: 2em;
        height: 2em;
        color: var(--modal-btn-close-color);
        background: transparent var(--modal-btn-close) center/1em auto no-repeat;
        border: 0;
        border-radius: 0.375rem;
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 0;
        border-radius: 100px;
        border: 1px solid transparent;

        @media screen and (min-width: 768px) {
            top: 40px;
            right: 40px;
        }

        &:hover {
            border: 1px solid var(--modal-btn-close-color);
        }
    }


    &-header &-btn-close {
        padding: calc(var(--modal-padding) * .5) calc(var(--modal-padding) * .5);
        margin: calc(-.5 * var(--modal-padding)) calc(-.5 * var(--modal-padding)) calc(-.5 * var(--modal-padding)) auto;
    }

    &-instructions {
        width: 100%;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.4;
        text-align: left;

        @media screen and (min-width: 768px) {
            font-size: 20px;
        }
    }

    &-footer {
        font-size: 18px;
        font-weight: 600;
        gap: 15px;
        flex-wrap: wrap;

        svg {
            color: #183D7B;
        }

        a {
            color: #183D7B;
            text-decoration: none;

            &:hover {
                cursor: pointer;
                color: #183D7B;
                text-decoration: underline;
            }
        }

        &-copy {
            display: none;

            @media screen and (min-width: 768px) {
                display: block;
            }
        }

        &-share {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    } 
}