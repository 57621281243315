@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

$teal: #18DCD1;

$bg-teal: #00B3A4;
$bg-purple: #80175E;
$bg-gold: #F6C106;

$super-red: #C20D2C;
$super-orange: #FB8213;
$super-purple: #8A049F;
$super-green: #07A029;
$super-teal: #0DC3B9;
$super-gold: #FFCF35;

$font-family-header: 'Bangers', cursive;
$font-family-body: 'Open Sans', sans-serif;

.super-hero {
  --super-hero-btn-bg: $teal;
  --super-hero-btn-shadow: 4px 4px 0px 0px #000;
  --super-hero-btn-border-radius: 60px;
  color: #000;
  font-family: $font-family-body;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  &-container {
    width: 100%;
    max-width: 1276px;
    margin-left: auto;
    margin-right: auto;
  }

  &-form {
    background: #FFF;

    @media screen and (min-width: 1024px) {
      padding: 24px;
      border: 2px solid #000;
    }

    * {
      box-sizing: border-box;
    }

    label, input, select {
      display: block;
      margin-top: 10px;
    }

    input[type="submit"] {
      margin-top: 30px;
    }

    &-step {
      --super-hero-img-text-display: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 750px;
      padding: 5px 15px;
      position: relative;
      text-align: center;
      background: #fff;
      border: 8px solid #000;
      overflow: hidden;

      @media screen and (min-width: 768px) {
      }

      @media screen and (min-width: 1024px) {
        padding: 68px;
        height: 800px;
      }

      * {
        position: relative;
      }

      &[data-step="1"] {
        align-items: flex-end;
        justify-content: center;
        padding: 40px 15px;

        @media screen and (min-width: 1024px) {
          padding: 200px 160px;
          justify-content: flex-start;
        }
      }

      &[data-step="2"] {
        background-color: $bg-teal;
      }

      &[data-step="3"] {
        background-color: $bg-gold;
      }
      
      &[data-step="4"] {
        padding: 24px 15px;
        background-color: #b03535;

        @media screen and (min-width: 1024px) {
          padding: 40px 60px;
        }
      }
    }

    &-bg, &-mobile-bg, &-superHerosImg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-bg {
      display: none;

      @media screen and (min-width: 720px) {
        display: block;
      }
    }

    &-mobile-bg {
      display: block;

      @media screen and (min-width: 720px) {
        display: none;
      }
    }

    &-superHerosImg {
      height: 460px;
      top: 50px;
      object-position: 22%;
      z-index: 3;

      @media screen and (min-width: 720px) {
        top: auto;
        height: 100%;
      }

      @media screen and (min-width: 1024px) {
        object-fit: cover;
        object-position: center;
      }
    }

    &-raster-top {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      max-width: 160px;

      @media screen and (min-width: 1024px) {
        max-width: 225px;
      }
    }

    &-raster-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      max-width: 160px;

      @media screen and (min-width: 1024px) {
        max-width: 225px;
      }
    }

    &-heading {
      z-index: 4;
      max-width: 500px;
      margin-top: 40px;

      @media screen and (min-width: 720px) {
        margin-top: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &-step-label {
      color: #fff;
      font-size: 28px;
      font-weight: 800;
      font-family: $font-family-body;
      text-shadow: 3px 3px 0px #000;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-line {
        width: 36px;
        border-top: 1px solid #FFFFFF;
        box-shadow: -2px 2px 0px #000000;
        display: inline-block;
        margin: 0 20px;
      }
    }

    &-label {
      color: #fff;
      font-size: 40px;
      font-weight: 800;
      line-height: 1;
      font-family: $font-family-body;
      text-shadow: 6px 6px 0px #000;
      -webkit-text-stroke-width: 2;
      -webkit-text-stroke-color: #000;
      margin-top: 5px;
      margin-bottom: 50px;

      @media screen and (min-width: 720px) {
        font-size: 64px;
        text-shadow: -6px 6px 0px #000000;
      }

      &-pre {
        font-size: 18px;
        font-weight: 800;
        font-family: $font-family-body;
      }
    }

    &-step[data-step="1"] &-label {
      z-index: 2;
      margin-bottom: 0;
      font-size: 72px;
      text-shadow: -6px 6px 0px #000000;

      @media screen and (min-width: 720px) {
        font-size: 120px;
        text-shadow: -12px 12px 0px #000000;
      }
    }

    &-step[data-step="4"] &-label{
      color: #000;
      font-size: 18px;
      margin-bottom: 30px;
      font-weight: 800;
      text-shadow: none;
      font-family: $font-family-body;

      @media screen and (min-width: 720px) {
        font-size: 32px;
        margin-bottom: 50px;
      }
    }

    &-btns {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 30px;
      margin-bottom: 30px;
      justify-content: center;
      align-items: center;
    }

    &-step[data-step="4"] &-btns {
      margin: 0;
      bottom: 0;
      align-items: flex-start;
      justify-content: flex-start;

      @media screen and (min-width: 720px) {
        margin-top: 30px;
      }

      @media screen and (min-width: 1440px) {
        margin-top: 100px;
      }
    }

    &-btn {
      color: #000;
      cursor: pointer;
      display: inline-block;
      background-color: $teal;
      border: 2px solid #000;
      box-shadow: var(--super-hero-btn-shadow);
      border-radius: var(--super-hero-btn-border-radius);
      font-family: $font-family-body;
      font-weight: 800;
      font-size: 18px;
      line-height: 1.2;
      padding: 16px 80px;
      z-index: 4;

      @media screen and (min-width: 720px) {
        font-size: 32px;
        padding: 24px 80px;
      }

      &:hover {
        background-color: $super-gold;
      }
    }

    &-step[data-step="1"] &-btn {
      width: 100%;
      font-size: 24px;
      margin-top: 40px;

      @media screen and (min-width: 720px) {
        width: auto;
        font-size: 32px;
        margin-top: 0;
      }
    }

    &-step[data-step="4"] &-btn {
      font-size: 18px;
      font-weight: 800;
      padding: 16px 40px;
      text-align: center;
      width: 100%;
      border-radius: var(--super-hero-btn-border-radius);

      @media screen and (min-width: 720px) {
        font-size: 24px;
        padding: 20px 40px;
      }
    }

    &-superHeroPowerBg {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      object-fit: fill;
    }

    &-superHeroPowerImg {
      position: absolute;
      left: 0;
      bottom: 0;
      display: none;

      @media screen and (min-width: 1200px) {
        display: block;
      }
    }

    &-superHeroTypeBg {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      object-fit: fill;
    }

    &-superHeroTypeImg {
      position: absolute;
      right: 0;
      bottom: 0;
      display: none;

      @media screen and (min-width: 1200px) {
        display: block;
      }
    }

    &-superHeroNameBg, &-superHeroNameMobileBg {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &-superHeroNameBg {
      display: none;

      @media screen and (min-width: 720px) {
        display: block;
      }
    }

    &-superHeroNameMobileBg {
      display: block;

      @media screen and (min-width: 720px) {
        display: none;
      }
    }

    &-cards {
      display: flex;
      gap: 15px;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 320px;
      

      @media screen and (min-width: 768px) {
        gap: 40px;
        max-width: 680px;
        margin-bottom: 100px;
      }
    }

    &-result {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      gap: 40px;
      text-align: left;
      position: relative;
      z-index: 4;
      color: #fff;
      height: 100%;

      @media screen and (min-width: 720px) {
        max-width: 600px;
      }

      @media screen and (min-width: 1024px) {
        flex-direction: row-reverse;
        gap: 80px;
        max-width: 100%;
      }

      &-col {
        flex: 1;
        height: 100%;

        &:last-child {
          flex: 0 1 50%;

          @media screen and (min-width: 1200px) {
            flex: 0 1 540px;
          }
        }
      }

      &-heading {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 4rem;
      }

      &-content {
        

        @media screen and (min-width: 1024px) {
          margin-bottom: 100px;
        }
      }

      &-intro {
        font-size: 1.25rem;
        font-weight: 800;
        text-shadow: 2px 2px 0px #000;
        margin-bottom: 24px;

        @media screen and (min-width: 1200px) {
          font-size: 1.5rem;
          margin-top: 80px;
          margin-bottom: 40px;
        }

        a {
          color: #fff;
          text-underline-offset: 4px;
        }
      }

      &-card {
        background-color: #fff;
        border-radius: 8px;
        border: 6px solid #000;
        background: #FFF;
        box-shadow: 4px 4px 0px 0px #000;
        overflow: hidden;

        &-head {
          color: #fff;
          text-align: center;
          position: relative;
          padding: 20px 24px 50px;
          background-color: #a7a7a7;
          border-bottom: 6px solid #000;
          background-size: 80px;
          background-position: bottom;
          background-repeat: space no-repeat;

          @media screen and (min-width: 1200px) {
            padding: 60px 40px;
          }


          &.super-purple {
            background-color: $super-purple;
          }
  
          &.super-red {
            background-color: $super-red;
          }
  
          &.super-orange {
            background-color: $super-orange;
          }
  
          &.super-green {
            background-color: $super-green;
          }
  
          &.super-teal {
            background-color: $super-teal;
          }

          &-bg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            svg {
              position: relative;
              width: 100%;
              height: auto;
              color: rgba(255, 255, 255, 0.20);
            }
          }
        }

        &-icon {
          $icon-size: 60px;
          background: #656565;
          width: $icon-size;
          height: $icon-size;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(45deg);
          position: absolute;
          top: 100%;
          left: 50%;
          margin-top: calc(-1 * ($icon-size / 2));
          margin-left: calc(-1 * ($icon-size / 2));
          border-radius: 5px;
          border: 4px solid #000;
          box-shadow: 4px 0 0px 0px #000;

          @media screen and (min-width: 1200px) {
            $icon-size: 75px;
            width: $icon-size;
            height: $icon-size;
            margin-top: calc(-1 * ($icon-size / 2));
            margin-left: calc(-1 * ($icon-size / 2));
          }

          &.super-purple {
            background-color: $super-purple;
          }
  
          &.super-red {
            background-color: $super-red;
          }
  
          &.super-orange {
            background-color: $super-orange;
          }
  
          &.super-green {
            background-color: $super-green;
          }
  
          &.super-teal {
            background-color: $super-teal;
          }

          svg {
            $icon-size: 60px;
            width: calc($icon-size - 25px);
            height: calc($icon-size - 25px);
            transform: rotate(-45deg);

            @media screen and (min-width: 1200px) {
              $icon-size: 75px;
              width: calc($icon-size - 25px);
              height: calc($icon-size - 25px);
            }

          }
        }

        &-body {
          color: #1E1E1E;
          padding: 50px 24px 24px;

          @media screen and (min-width: 1200px) {
            padding: 80px 40px 40px;
          }

          &-attr {
            font-size: 1rem;
            margin: 0 0 20px;

            @media screen and (min-width: 1200px) {
              font-size: 1.25rem;
            }

            label {
              margin: 0;
              font-weight: bold;
              display: inline-block;
            }
            
          }

          &-origin {
            font-size: 1rem;
            margin: 20px 0;

            @media screen and (min-width: 1200px) {
              font-size: 1.25rem;
              margin: 24px 0;
            }

            label {
              margin: 0;
              font-weight: bold;
              display: inline-block;
            }
          }

          &-logo {
            width: 140px;
            height: auto;
            margin: 0;

            @media screen and (min-width: 1200px) {
              width: 200px;
            }
          }
        }

        &-heading {
          color: #fff;
          font-size: 28px;
          font-weight: 800;
          text-shadow: 4px 4px 0px #000;
          stroke-width: 1;
          -webkit-text-stroke-width: 1;
          -webkit-text-stroke-color: #000;
          text-transform: capitalize;

          @media screen and (min-width: 1200px) {
            font-size: 38px;
            stroke-width: 2;
            -webkit-text-stroke-width: 2;
          }
        }

        &-subheading {
          color: #fff;
          font-size: .75rem;
          font-weight: 700;
          text-transform: uppercase;
          text-shadow: 2px 2px 0px #000;
          margin-bottom: .75rem;

          @media screen and (min-width: 1024px) {
            font-size: 1.25rem;
            margin-bottom: 1.25rem;
            text-shadow: 4px 4px 0px #000;
          }
        }
      }

      &-love-img {
        display: none;
        position: absolute;

        @media screen and (min-width: 1024px) {
          display: block;
          width: 780px;
          right: -24px;
          bottom: 24px;
        }

        &.mobile {
          width: 120%;
          margin: 0 -10%;
          position: relative;
          display: block;
          margin-top: 40px;

          @media screen and (min-width: 1024px) {
            display: none;
          }
        }
      }
    }

    &-superHeroType &-cards {
      max-width: 480px;
    }

    &-card {
      width: 150px;
      height: 150px;
      font-size: 16px;
      font-weight: 800;
      background-color: #FFF;
      border: 3px solid #000;
      box-shadow: 4px 4px 0px 0px #000;
      border-radius: 8px;

      @media screen and (min-width: 768px) {
        font-size: 22px;
        width: 200px;
        height: 200px;
      }

      &:hover {
        cursor: pointer;
        box-shadow: 6px 6px 0px 0px #000;
      }

      &-outer {
        height: 0;
        padding-bottom: 100%;
        position: relative;
      }

      &-inner {
        display: flex;
        row-gap: 10px;
        padding: 15px;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;

        @media screen and (min-width: 768px) {
          padding: 20px;
        }
      }

      &-svg {
        width: 66px;
        height: 66px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        @media screen and (min-width: 768px) {
          width: 90px;
          height: 90px;
        }

        &.super-purple {
          background-color: $super-purple;
        }

        &.super-red {
          background-color: $super-red;
        }

        &.super-orange {
          background-color: $super-orange;
        }

        &.super-green {
          background-color: $super-green;
        }

        &.super-teal {
          background-color: $super-teal;
        }

        svg {
          width: auto;
          height: 30px;

          @media screen and (min-width: 768px) {
            height: 50px;
          }
        }

        svg#{&}-hover {
          display: none;
          position: absolute;
          width: 140px;
          height: 140px;
        }

        &.super-purple &-hover {
          color: $super-purple;
        }

        &.super-red &-hover {
          color: $super-red;
        }

        &.super-orange &-hover {
          color: $super-orange;
        }

        &.super-green &-hover {
          color: $super-green;
        }

        &.super-teal &-hover {
          color: $super-teal;
        }
      }

      &:hover &-svg {
        background-color: transparent;
      }

      &:hover &-svg-hover {
        display: block;
      }

      &-label {
        text-align: center;
      }
    }

    &-name {
      color: #183D7B;
      font-size: 48px;
      line-height: 1;
      font-weight: 400;
      font-family: $font-family-header;
      text-shadow: -3px 3px 0px #CCCCCC;
      z-index: 1;

      @media screen and (min-width: 720px) {
        font-size: 80px;
        text-shadow: -4px 4px 0px #CCCCCC;
      }
    }

    &-rename {
      cursor: pointer;
      font-size: 14px;
      margin-top: 30px;
      z-index: 1;

      @media screen and (min-width: 720px) {
        font-size: 16px;
      }

      &-icon {
        margin-right: 5px;
      }
    }

    &-text {
      z-index: 1;
      font-size: 14px;
      max-width: 280px;
      margin-bottom: -100px;

      a {
        color: #183D7B;
      }

      @media screen and (min-width: 720px) {
        font-size: 20px;
        max-width: 640px;
        margin-bottom: -155px;
      }
    }

    &-img-text {
      z-index: 0;
      font-size: 14px;
      max-width: 280px;
      position: relative;

      @media screen and (min-width: 720px) {
        font-size: 20px;
        max-width: 640px;
        margin-bottom: 0;
      }

      &-overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }

    &-logo {
      display: block;
      width: 150px;
      margin: 10px auto 0;

      @media screen and (min-width: 720px) {
        width: 200px;
        margin: 30px auto 0;
      }
    }

    &-hr {
      z-index: 1;
      height: 1px;
      background: #CCC;
      width: 200px;
      margin: 20px 0;

      @media screen and (min-width: 720px) {
        margin: 40px 0;
        width: 420px;
      }
    }
  }
}